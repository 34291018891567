@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600;700&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #ff9400;
}

a:hover {
  color: #cf3535;
}

/*----------------------------------------------------
@File: Default Styles
@Author: Rocky Ahmed
@URL: http://wethemez.com
Author E-mail: rockybd1995@gmail.com

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */
/*=====================================================================
@Template Name: Airtech
@Author: Rocky Ahmed
@Developed By: Rocky Ahmed
@Developer URL: http://rocky.wethemez.com 
Author E-mail: rockybd1995@gmail.com

@Default Styles

Table of Content:
01/ Variables
02/ predefin
03/ header
04/ button
05/ banner
06/ breadcrumb
07/ about
08/ team
09/ project 
10/ price 
11/ team 
12/ blog 
13/ video  
14/ features  
15/ career  
16/ contact 
17/ footer

=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
/*Color Variables*/
/*=================== fonts ====================*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,700i|Lato:300,400,400i,700,900|Playfair+Display:400,400i,700,700i|Poppins:200,200i,300,400,400i,500,500i,600,600i,700&display=swap");
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}

.row.m0 {
  margin: 0px;
}

body {
  line-height: 28px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  color: #797979;
  background: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

i:before {
  margin-left: 0px !important;
}

button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.m0 {
  margin: 0px;
}

.p0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p_50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.p_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pad_top {
  padding-top: 100px;
}

.pad_btm {
  padding-bottom: 100px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.border_class .container {
  border-bottom: 1px solid #e4e4e4;
}

.bg_gray {
  background: #f0edf7;
}

.border_line {
  border-bottom: 1px solid #dddddd;
}

/* Main Title Area css
============================================================================================ */
.center_title_big {
  margin-bottom: 50px;
}

.center_title_big h2 {
  color: #051d3e;
  line-height: 44px;
  font-size: 32px;
  margin-bottom: 15px;
}

.center_title_big p {
  margin-bottom: 0px;
}

.single_title {
  margin-bottom: 50px;
}

.single_title h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #000;
}

.single_title.blue h2 {
  color: #051d3e;
}

.main_title {
  margin-bottom: 45px;
}

.main_title h2 {
  font-size: 32px;
  color: #051d3e;
}

.main_title p {
  margin-bottom: 0px;
}

.main_title.white h2 {
  color: #fff;
}

.main_title.white p {
  color: rgba(255, 255, 255, 0.7);
}

.single_border_title {
  text-align: center;
  margin-bottom: 60px;
}

.single_border_title h2, .single_border_title h4 {
  margin-bottom: 0px;
  color: #051d3e;
  font-size: 32px;
  position: relative;
}

.single_border_title h4 {
  font-size: 24px;
}

.single_border_title h2:before, .single_border_title h4:before {
  content: "";
  width: 65px;
  height: 2px;
  background: #bd2433;
  position: absolute;
  left: 50%;
  bottom: -13px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* End Main Title Area css
============================================================================================ */
@-webkit-keyframes runpulse {
  0% {
    -webkit-transform: scale(0.68);
    transform: scale(0.68);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes runpulse {
  0% {
    -webkit-transform: scale(0.68);
    transform: scale(0.68);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Header Area css
============================================================================================ */
.header_area {
  position: absolute;
  left: 0px;
  width: 100%;
  z-index: 99999;
  top: 0px;
}

.header_area + row, .header_area + div, .header_area + section {
  margin-top: 166px;
}

.header_area.navbar_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  background: transparent;
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: background 500ms ease, -webkit-transform 500ms ease;
  -o-transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease, -webkit-transform 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  z-index: 40;
  margin-top: 0px;
}

.header_area.navbar_fixed .main_menu .navbar.navbar-expand-lg {
  -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.14);
}

.logo_part {
  background: #04122c;
  padding: 35px 0px 50px 0px;
  border-bottom: 1px dashed #0a406d;
  position: relative;
}

.logo_part:before {
  content: "";
  border-bottom: 1px dashed #0a406d;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 8px;
}

.logo_part .logo_inner .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.logo_part .logo_inner .right .media {
  margin-right: 55px;
  position: relative;
}

.logo_part .logo_inner .right .media:before {
  content: "";
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/line-shap.png) no-repeat scroll center center;
  width: 2px;
  height: 82px;
  position: absolute;
  right: -28px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.logo_part .logo_inner .right .media:last-child {
  margin-right: 0px;
}

.logo_part .logo_inner .right .media:last-child:before {
  display: none;
}

.logo_part .logo_inner .right .media .d-flex {
  padding-right: 13px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  vertical-align: middle;
}

.logo_part .logo_inner .right .media .media-body {
  vertical-align: middle;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.logo_part .logo_inner .right .media .media-body h3 {
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: .54px;
  margin-bottom: 0px;
}

.logo_part .logo_inner .right .media .media-body h4 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: #6bbbf0;
}

.logo_part .logo_inner .right .media .media-body h5 {
  color: #fab505;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 0px;
}

.main_menu {
  margin-top: -30px;
}

.main_menu .navbar {
  background: #0e59a9 !important;
  position: relative;
  padding: 0px;
}

.main_menu .navbar:before {
  content: "";
  height: 30px;
  background: #0f55a9;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.main_menu .navbar .navbar-brand {
  display: none;
}

.main_menu .navbar .nav.navbar-nav {
  padding-left: 40px;
}

.main_menu .navbar .nav.navbar-nav li {
  margin-right: 50px;
}

.main_menu .navbar .nav.navbar-nav li a {
  line-height: 60px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
}

.main_menu .navbar .nav.navbar-nav li a:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -25px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 8px 0 8px;
  border-color: #0f55a9 transparent transparent transparent;
  opacity: 0;
  margin: 0px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.main_menu .navbar .nav.navbar-nav li a:before {
  content: "";
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/line-shap-2.png) no-repeat scroll center center;
  height: 43px;
  width: 2px;
  position: absolute;
  right: -25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main_menu .navbar .nav.navbar-nav li .mobile_dropdown {
  display: none;
  cursor: pointer;
}

.main_menu .navbar .nav.navbar-nav li:last-child {
  margin-right: 0px;
}

.main_menu .navbar .nav.navbar-nav li:last-child > a:before {
  display: none;
}

@media (min-width: 992px) {
  .main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -40px;
    min-width: 256px;
    background: #fff;
    margin: 0px;
    text-align: left;
    opacity: 0;
    -webkit-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    visibility: hidden;
    display: block;
    border: none;
    padding: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
  }
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu li {
  margin-right: 0px;
  display: block;
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu li a {
  display: block;
  line-height: 49px;
  color: #2a3274;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  padding: 0px 30px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  font-weight: 600;
  text-shadow: none;
  border-bottom: 1px solid #efefef;
  position: relative;
  padding-left: 25px;
  text-transform: capitalize;
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu li a:before {
  content: "";
  width: 0px;
  height: 1px;
  background: #d63343;
  position: absolute;
  left: 27px;
  top: 50%;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-top: -1px;
  opacity: 0;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu li a:after {
  display: none;
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu li:hover a {
  color: #d63343;
  padding-left: 50px;
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu li:hover a:before {
  opacity: 1;
  width: 12px;
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu li:last-child a {
  border-bottom: 0px;
}

@media (min-width: 992px) {
  .main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu .submenu .dropdown-menu {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 97%;
    right: auto;
    min-width: 270px;
    top: -3px;
    -webkit-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    padding: 0px;
  }
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu .submenu .dropdown-menu li a {
  background: #fff;
  color: #242424;
}

.main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu .submenu .dropdown-menu li:hover a {
  background: #d63343;
  color: #fff;
}

@media (min-width: 992px) {
  .main_menu .navbar .nav.navbar-nav li.submenu .dropdown-menu .submenu:hover.submenu .dropdown-menu {
    visibility: visible;
    left: 0px;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .main_menu .navbar .nav.navbar-nav li:hover.submenu a:after {
    opacity: 1;
    z-index: 9999;
  }
  .main_menu .navbar .nav.navbar-nav li:hover.submenu .dropdown-menu {
    left: -26px;
    visibility: visible;
    opacity: 1;
  }
}

.main_menu .navbar .nav.navbar-nav.navbar-right {
  position: relative;
}

.main_menu .navbar .nav.navbar-nav.navbar-right li a {
  line-height: normal;
  color: #fff;
}

.main_menu .navbar .nav.navbar-nav.navbar-right li.request_btn a {
  background: #bd2433;
  line-height: 60px;
  display: inline-block;
  padding: 0px 34px 0px 16px;
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: .51px;
  position: relative;
}

.main_menu .navbar .nav.navbar-nav.navbar-right li.request_btn a:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 60px 40px;
  border-color: transparent transparent #bd2433 transparent;
  position: absolute;
  left: -40px;
  top: 0px;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  display: block;
}

.menu_two + row, .menu_two + div, .menu_two + section {
  margin-top: 116px;
}

.menu_two .logo_part {
  background: #fff;
  border: none;
  border-top: 6px solid #8d8e94;
  padding: 17px 0px;
}

.menu_two .logo_part:before {
  display: none;
}

.menu_two .logo_part .logo_inner .right .media .media-body h3 {
  color: #545454;
}

.menu_two .logo_part .logo_inner .right .media .media-body h4 {
  color: #545454;
}

.menu_two .logo_part .logo_inner .right .media .media-body h5 {
  color: #000000;
}

.menu_two .main_menu {
  margin-top: 0px;
}

.menu_three + row, .menu_three + div, .menu_three + section {
  margin-top: 137px;
}

.wood-grain-bg {
  background: url("https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/wood-bg.png");
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  opacity: 1;
  z-index: 1;
}

@media (min-width: 700px) {
  .wood-grain-bg {
    background: url("https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/wood-bg.png");
  }
}

.wood-grain-bg-divider {
  background: url("https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/wood-grain.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  top: 0;
  width: 100%;
  height: 50px;
  opacity: 1;
  z-index: 1;
}

.wood-grain-bg-divider .wood-grain-bg-overlay {
  width: 100%;
  background: linear-gradient(-120deg, #4b371a, #523205);
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  opacity: 0.7;
  z-index: 1;
}

.contact_info {
  z-index: 2;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_three .contact_info .info_inner a {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #ff9400;
  margin-right: 50px;
  line-height: 51px;
  z-index: 2;
  font-weight: bold;
  letter-spacing: 0.25px;
  text-shadow: 1px 1px 2px #000;
}

.menu_three .contact_info .info_inner a img {
  padding-right: 6px;
}

.menu_three .contact_info .info_inner a:last-child {
  margin-right: 0px;
  margin-left: 100px;
}

.menu_three .main_menu {
  margin-top: 0px;
  background: white;
  position: relative;
  /*background-image: url("https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/wood.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
}

.menu_three .main_menu:before {
  content: '';
  width: 50%;
  height: 139px;
  background-color: white;
  position: absolute;
  top: -60px;
  left: -338px;
  z-index: 1;
}

.menu_three .main_menu:after {
  content: "";
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/shadow-menu.png) no-repeat scroll center center;
  position: absolute;
  left: 50%;
  top: -50px;
  width: 74px;
  height: 136px;
  margin-left: -330px;
  z-index: 2;
}

.menu_three .main_menu .navbar {
  background: transparent !important;
}

.menu_three .main_menu .navbar:before {
  display: none;
}

.menu_three .main_menu .navbar .navbar-brand {
  display: inline-block;
  z-index: 3;
}

.menu_three .main_menu .navbar .navbar-brand:before {
  content: '';
  position: absolute;
  top: -60px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -375px;
  height: 149px;
  width: 80px;
  background: white;
  -webkit-transform: skewX(26deg);
  -ms-transform: skewX(26deg);
  transform: skewX(26deg);
  z-index: 1;
}

.menu_three .main_menu .navbar .navbar-brand img {
  margin-top: -50px;
  z-index: 5;
  position: relative;
}

.menu_three .main_menu .navbar .nav.navbar-nav {
  padding-left: 0px;
}

.menu_three .main_menu .navbar .nav.navbar-nav > li {
  margin-right: 30px;
}

.menu_three .main_menu .navbar .nav.navbar-nav > li > a {
  color: black;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.menu_three .main_menu .navbar .nav.navbar-nav > li > a:after {
  display: none;
}

.menu_three .main_menu .navbar .nav.navbar-nav > li > a:before {
  display: none;
}

.menu_three .main_menu .navbar .nav.navbar-nav.navbar-right {
  position: relative;
  padding-left: 10px;
}

.menu_three .main_menu .navbar .nav.navbar-nav.navbar-right li {
  margin-right: 0px;
}

.menu_three .main_menu .navbar .nav.navbar-nav.navbar-right li.search a {
  position: relative;
}

.menu_three .main_menu .navbar .nav.navbar-nav.navbar-right li.search a:before {
  content: "";
  height: 14px;
  width: 1px;
  color: white;
  position: absolute;
  left: -20px;
  top: 46%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
}

.menu_three.navbar_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: background 500ms ease, -webkit-transform 500ms ease;
  -o-transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease, -webkit-transform 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  z-index: 40;
  margin-top: 0px;
}

.menu_three.navbar_fixed .main_menu img {
  max-width: 105px;
}

.menu_three.navbar_fixed .main_menu .parent-company-banner {
  display: none;
}

.menu_three.navbar_fixed .main_menu .navbar.navbar-expand-lg {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.menu_three.navbar_fixed .main_menu .navbar .navbar-brand img {
  margin-top: 0px;
}

.menu_four + row, .menu_four + div, .menu_four + section {
  margin-top: 138px;
}

.menu_four .top_bar {
  background-image: -moz-linear-gradient(0deg, #23679d 0%, #113c73 70%);
  background-image: -webkit-linear-gradient(0deg, #23679d 0%, #113c73 70%);
  background-image: -ms-linear-gradient(0deg, #23679d 0%, #113c73 70%);
  -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #fff;
}

.menu_four .top_bar .left h5 {
  color: #fff;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 50px;
  margin-bottom: 0px;
}

.menu_four .top_bar .left h5 i {
  font-size: 20px;
  padding-right: 13px;
}

.menu_four .top_bar .left h5 a {
  color: #fff;
  font-size: 19px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.menu_four .top_bar .right .nav li {
  margin-right: 34px;
}

.menu_four .top_bar .right .nav li:last-child {
  margin-right: 0px;
}

.menu_four .top_bar .right .nav li:last-child:before {
  display: none;
}

.menu_four .top_bar .right .nav li:first-child a:before {
  display: none;
}

.menu_four .top_bar .right .nav li a {
  font-size: 15px;
  color: #ddd4da;
  font-family: "Open Sans", sans-serif;
  line-height: 50px;
  position: relative;
}

.menu_four .top_bar .right .nav li a:before {
  content: "";
  height: 16px;
  width: 2px;
  background: #b7b5c3;
  position: absolute;
  left: -18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menu_four .top_bar .right .nav li .nice_select {
  border-radius: 0px;
  background: transparent;
  border: none;
  padding-left: 0px;
  height: 50px;
  min-width: 55px;
  padding-right: 16px;
}

.menu_four .top_bar .right .nav li .nice_select span {
  text-transform: uppercase;
  color: #ddd4da;
  line-height: 50px;
}

.menu_four .top_bar .right .nav li .nice_select:after {
  content: "\f0d7";
  font: normal normal normal 14px/1 FontAwesome;
  border: none;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  color: #ddd4da;
  margin-top: -7px;
}

.menu_four .top_bar .right .nav li .nice_select .list {
  border: none;
  border-radius: 0px;
  margin: 0px;
}

.menu_four .top_bar .right .nav li .nice_select .list li {
  margin: 0px;
}

.menu_four .main_menu {
  margin-top: 0px;
  background-image: -moz-linear-gradient(90deg, #e7e7e7 0%, #faf6f3 100%);
  background-image: -webkit-linear-gradient(90deg, #e7e7e7 0%, #faf6f3 100%);
  background-image: -ms-linear-gradient(90deg, #e7e7e7 0%, #faf6f3 100%);
  position: relative;
}

.menu_four .main_menu .navbar {
  background: transparent !important;
}

.menu_four .main_menu .navbar:before {
  display: none;
}

.menu_four .main_menu .navbar .navbar-brand {
  display: inline-block;
  z-index: 3;
}

.menu_four .main_menu .navbar .navbar-brand img {
  z-index: 5;
  position: relative;
}

.menu_four .main_menu .navbar .nav.navbar-nav {
  padding-left: 0px;
}

.menu_four .main_menu .navbar .nav.navbar-nav > li {
  margin-right: 30px;
}

.menu_four .main_menu .navbar .nav.navbar-nav > li > a {
  color: #000;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  line-height: 87px;
  text-transform: capitalize;
  font-weight: 500;
}

.menu_four .main_menu .navbar .nav.navbar-nav > li > a:after {
  display: none;
}

.menu_four .main_menu .navbar .nav.navbar-nav > li > a:before {
  display: none;
}

.menu_four .main_menu .navbar .nav.navbar-nav.navbar-right {
  position: relative;
  padding-left: 10px;
}

.menu_four .main_menu .navbar .nav.navbar-nav.navbar-right li {
  margin-right: 0px;
}

.menu_four .main_menu .navbar .nav.navbar-nav.navbar-right li.search {
  color: #000;
}

.menu_four .main_menu .navbar .nav.navbar-nav.navbar-right li.search a {
  position: relative;
  color: #000;
}

.menu_four .main_menu .navbar .nav.navbar-nav.navbar-right li.search a:before {
  content: "";
  height: 14px;
  width: 1px;
  color: #242424;
  position: absolute;
  left: -20px;
  top: 46%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
}

.menu_four.navbar_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  background-image: -moz-linear-gradient(90deg, #e7e7e7 0%, #faf6f3 100%);
  background-image: -webkit-linear-gradient(90deg, #e7e7e7 0%, #faf6f3 100%);
  background-image: -ms-linear-gradient(90deg, #e7e7e7 0%, #faf6f3 100%);
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: background 500ms ease, -webkit-transform 500ms ease;
  -o-transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease, -webkit-transform 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  z-index: 40;
  margin-top: 0px;
}

.menu_four.navbar_fixed .main_menu .navbar.navbar-expand-lg {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* End Header Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Button Area css
============================================================================================ */
.ab_bar_btn {
  text-decoration: underline;
  line-height: 50px;
  display: inline-block;
  border-radius: 5px;
  padding: 0px 28px;
  color: #d63343;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  z-index: 3;
}

.ab_bar_btn:after, .ab_bar_btn:before {
  content: "";
  position: absolute;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 5px;
}

.ab_bar_btn:before {
  left: 0px;
  z-index: -1;
  opacity: 0;
  background: #d63343;
  -webkit-transform: scale(0.1, 1);
  -ms-transform: scale(0.1, 1);
  transform: scale(0.1, 1);
}

.ab_bar_btn:after {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #e7e7e7;
}

.ab_bar_btn:hover {
  color: #fff;
}

.ab_bar_btn:hover:before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}

.ab_bar_btn:hover:after {
  -webkit-transform: scale(1, 0.1);
  -ms-transform: scale(1, 0.1);
  transform: scale(1, 0.1);
  opacity: 0;
}

.more_btn {
  text-decoration: underline;
  font-size: 14px;
  color: #cf3535;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.more_btn:hover {
  color: #d63343;
  text-decoration: none;
}

.main_btn, .secondary_hero_btn {
  background: #cf3535;
  border-radius: 3px;
  display: inline-block;
  color: #fff;
  line-height: 40px;
  border: 1px solid #cf3535;
  padding: 0px 22px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  z-index: 10;
}
.main_btn.white_btn {
  background: #fff;
  border-radius: 3px;
  display: inline-block;
  color: #cf3535;
  line-height: 40px;
  border: 1px solid #fff;
  padding: 0px 22px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  z-index: 10;
}
.main_btn.white_btn_alt {
  background: transparent;
  border-radius: 3px;
  display: inline-block;
  color: #fff;
  line-height: 40px;
  border: 1px solid #fff;
  padding: 0px 22px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  z-index: 10;
}
.main_btn.white_btn_alt:hover {
  background: #fff;
  color: #cf3535;
}

a.secondary-button {
  background: #fff;
  border-radius: 3px;
  display: inline-block;
  color: #cf3535;
  line-height: 40px;
  border: 1px solid #cf3535;
  padding: 0px 22px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  z-index: 10;
}

.ml-15px {
  margin-left: 15px;
}

a.secondary-button:hover {
  background: #cf3535;
  color: #fff;
}

.main_btn:hover, .secondary_hero_btn:hover, .main_btn.main_btn_alt-bg:hover {
  border-color: #fff;
  background: transparent;
  color: #fff;
}

.main_btn.main_btn_alt-bg:hover {
  border-color: #cf3535;
  background: transparent;
  color: #cf3535;
}

.secondary_hero_btn {
  background: #fff;
  border: 1px solid #fff;
  color: #cf3535;
}

.submit_btn {
  background: #cf3535;
  border-radius: 3px;
  display: inline-block;
  color: #fff;
  line-height: 40px;
  border: 1px solid #cf3535;
  padding: 0px 22px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.submit_btn:hover {
  border-color: #cf3535;
  background: transparent;
  color: #cf3535;
}

/* End Button Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Home Banner Area css
============================================================================================ */
.home_banner_area {
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/home-banner.jpg) no-repeat scroll center center;
  background-size: cover;
  padding-top: 30px;
}

.home_banner_inner {
  position: relative;
  z-index: 5;
}

.home_banner_inner .left_text {
  padding-top: 80px;
}

.home_banner_inner .left_text h5 {
  color: #37a8f4;
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: bold;
}

.home_banner_inner .left_text h3 {
  font-size: 44px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.home_banner_inner .left_text p {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  line-height: 35px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 10px;
}

.home_banner_inner .left_text .tel {
  display: block;
  font-size: 28px;
  color: #f7ce1f;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 35px;
}

.home_banner_inner .left_text .main_btn {
  padding: 0px 30px;
  line-height: 46px;
}

.schedule_box {
  background: #b2101e;
  max-width: 310px;
  float: right;
  padding: 0px 27px;
  position: relative;
}

.schedule_box:before {
  content: "";
  position: absolute;
  left: -260px;
  bottom: 0px;
  width: 303px;
  height: 508px;
  z-index: -1;
}

.schedule_box img {
  margin: auto;
  display: block;
  margin-top: 25px;
  margin-bottom: 10px;
}

.schedule_box h4 {
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  font-weight: bold;
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-size: 40px;
  margin-bottom: 3px;
}

.schedule_box h5 {
  text-align: center;
  color: #fff;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 18px;
}

.schedule_box .contact_us_form .form-group {
  margin-bottom: 8px;
}

.schedule_box .contact_us_form .form-group input {
  border: 1px solid #ca525e;
  background: #9e101e;
  height: 40px;
  padding: 0px 20px;
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group input.placeholder {
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group input:-moz-placeholder {
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group input::-moz-placeholder {
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group input::-webkit-input-placeholder {
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group textarea {
  border: 1px solid #ca525e;
  background: #9e101e;
  height: 76px;
  padding: 10px 20px;
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group textarea.placeholder {
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group textarea:-moz-placeholder {
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group textarea::-moz-placeholder {
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group textarea::-webkit-input-placeholder {
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.schedule_box .contact_us_form .form-group .submit_btn {
  width: 100%;
  line-height: 46px;
  background-image: -moz-linear-gradient(90deg, #fbb101 0%, #f7cf20 100%);
  background-image: -webkit-linear-gradient(90deg, #fbb101 0%, #f7cf20 100%);
  background-image: -ms-linear-gradient(90deg, #fbb101 0%, #f7cf20 100%);
  margin-top: 8px;
  margin-bottom: 25px;
  color: #242424;
}

/* End Home Banner Area css
============================================================================================ */
/* Breadcrumb Area css
============================================================================================ */
.breadcrumb_area {
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/breadcrumb-banner.png) no-repeat scroll center center;
  background-size: cover;
  padding-top: 110px;
  padding-bottom: 80px;
  overflow: hidden;
}

.breadcrumb_area.small-bg {
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/breadcrumb-banner.png) no-repeat scroll center center;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 30px;
  overflow: hidden;
}

.breadcrumb_area .breadcrumb_inner {
  position: relative;
}

.breadcrumb_area .breadcrumb_inner img {
  position: absolute;
  right: 0px;
  bottom: -128px;
}

.breadcrumb_area h2 {
  color: #fff;
  font-size: 1.25;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
  z-index: 3;
}

.breadcrumb_area .nav {
  position: relative;
  z-index: 3;
}

.breadcrumb_area .nav li {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-right: 20px;
  position: relative;
}

.breadcrumb_area .nav li:before {
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: -12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.breadcrumb_area .nav li a {
  font-size: 14px;
  font-weight: 500;
  color: #ff9400;
}

.breadcrumb_area .nav li:last-child {
  margin-right: 0px;
}

.breadcrumb_area .nav li:last-child:before {
  display: none;
}

/* End Breadcrumb Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.main_slider_area .rev_slider .slider_text_box .slider_box {
  background: #fff;
  padding: 60px 50px 65px 60px;
}

.main_slider_area .rev_slider .slider_text_box .slider_box h6 {
  font-size: 26px;
  font-family: "Playfair Display", serif;
  font-style: italic;
  color: #0e52a5;
  margin-bottom: 4px;
}

.main_slider_area .rev_slider .slider_text_box .slider_box h4 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #051d3e;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.main_slider_area .rev_slider .slider_text_box .slider_box p {
  font-size: 24px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  color: #1c1f2b;
  line-height: 35px;
  white-space: pre-wrap;
  margin-bottom: 5px;
}

.main_slider_area .rev_slider .slider_text_box .slider_box h5 {
  font-size: 28px;
  color: #bd2433;
  margin-bottom: 35px;
}

.main_slider_area .rev_slider .slider_text_box .first_text {
  font-size: 52px;
  font-weight: bold;
  color: #fff;
}

.main_slider_area .rev_slider .slider_text_box .first_text span {
  font-weight: 300;
}

.main_slider_area .rev_slider .slider_text_box .secand_text {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 32px;
}

.main_slider_area .rev_slider .slider_text_box .main_btn {
  -webkit-transition: all 300ms linear !important;
  -o-transition: all 300ms linear !important;
  transition: all 300ms linear !important;
}

.main_slider_area .rev_slider .slider_text_box .digit_text {
  color: #fff;
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: bold !important;
}

.main_slider_area .rev_slider .slider_text_box .main_btn.round {
  border-radius: 31px;
  line-height: 62px;
  padding: 0px 48px;
  text-transform: capitalize;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
}

.main_slider_area .rev_slider .slider_text_box .number_text a {
  color: #f7ce1f;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Choose Contract Area css
============================================================================================ */
.choose_contract_area {
  padding-bottom: 80px;
  background: linear-gradient(to right, rgb(244, 244, 244), rgb(255, 255, 255));
}

.choose_contract_area .contract_part .contract_text {
  margin-right: 70px;
}

.choose_contract_area .contract_part .contract_text h4 {
  font-size: 32px;
  color: #051d3e;
  margin-bottom: 12px;
}

.choose_contract_area .contract_part .contract_text p {
  margin-bottom: 20px;
}

.choose_contract_area .contract_part .contract_text p:nth-child(3) {
  margin-bottom: 0px;
}

.choose_contract_area .contract_part .contract_text .nav {
  width: 100%;
  margin-bottom: -5px;
  display: block;
  -webkit-columns: 2;
  columns: 2;
  margin-left: -20px;
  margin-top: 35px;
}

.choose_contract_area .contract_part .contract_text .nav li {
  margin-bottom: 5px;
  display: inline-block;
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
}

.choose_contract_area .contract_part .contract_text .nav li a {
  font-size: 17px;
  margin-bottom: 20px;
  color: #000;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
}

.choose_contract_area .contract_part .contract_text .nav li a img {
  position: absolute;
  left: 0px;
  top: 4px;
  display: block;
}

.contract_discount {
  background: #eff0f0;
  text-align: center;
  position: relative;
  padding-bottom: 30px;
}

.contract_discount:before {
  content: "";
  width: 92%;
  height: 90%;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.contract_discount h4 {
  background: #bd2433;
  color: #fff;
  margin-bottom: 0px;
  padding: 20px 0px 16px 0px;
  font-size: 22px;
  font-style: italic;
}

.contract_discount h4 span {
  font-size: 28px;
}

.contract_discount h5 {
  color: #152242;
  font-size: 18px;
  font-weight: 500;
  margin-top: 22px;
  margin-bottom: 13px;
  line-height: 27px;
}

.contract_discount p {
  font-size: 15px;
  font-weight: 500;
  color: #152242;
  opacity: .7;
  font-family: "Poppins", sans-serif;
  margin-bottom: 18px;
}

.contract_discount a {
  background: #1f65bd;
  display: inline-block;
  padding: 0px 20px;
  line-height: 38px;
  color: #fff;
  font-style: italic;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.contract_discount h6 {
  font-weight: normal;
  color: #9d9d9d;
  font-size: 14px;
  margin-bottom: 8px;
}

.print {
  display: block;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: #e31b1e;
  text-decoration: underline;
  margin-top: 14px;
  margin-bottom: 16px;
}

.more {
  display: block;
  text-align: center;
  text-decoration: underline;
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #7a7a7a;
}

.service_part {
  margin-top: 15px;
}

.service_part .service_contract_text {
  border-top: 1px solid #dbdbdb;
  margin-right: 70px;
  padding-top: 40px;
  margin-top: 30px;
}

.service_part .service_contract_text h4 {
  font-size: 24px;
  color: #242424;
  margin-bottom: 13px;
}

.service_part .service_contract_text .nav {
  max-width: 100%;
  width: 100%;
  margin-bottom: -8 px;
  display: block;
  -webkit-columns: 2;
  columns: 2;
  margin-left: -20px;
  margin-top: 35px;
}

.service_part .service_contract_text .nav li {
  margin-bottom: 8px;
  display: inline-block;
  margin-left: 20px;
  width: 100%;
}

.service_part .service_contract_text .nav li a {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
  position: relative;
  padding-left: 35px;
  display: block;
}

.service_part .service_contract_text .nav li a img {
  position: absolute;
  left: 0px;
  top: 4px;
  display: block;
}

.box_testi .title {
  background: #0062a3;
  vertical-align: middle;
  display: block;
}

.box_testi .title h4 {
  background: #bd2433;
  display: inline-block;
  margin-bottom: 0px;
  line-height: 60px;
  padding: 0px 40px 0px 35px;
  color: #fff;
  font-size: 22px;
  position: relative;
}

.box_testi .title h4:before {
  content: '';
  position: absolute;
  right: -45px;
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 60px 0 0 45px;
  border-color: transparent transparent transparent #bd2433;
}

.box_testi .box_testi_gray {
  background: #ebebeb;
  padding: 20px;
}

.box_testi .box_testi_gray .box_testi_slider {
  background: #fff;
  padding: 30px 30px 25px 30px;
}

.b_testi h3 {
  color: #bd2433;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 15px;
}

.b_testi h4 {
  color: #bd2433;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px;
}

.b_testi p {
  color: #1c425d;
  margin-bottom: 13px;
}

.b_testi h5 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #1f65bd;
  font-size: 14px;
  margin-bottom: 18px;
}

.b_testi .nav li {
  margin-right: 5px;
}

.b_testi .nav li a {
  font-size: 20px;
  color: #ff9400;
}

.b_testi .nav li:last-child {
  margin-right: 0px;
}

.choose_two .service_part {
  margin-top: 0px;
}

.choose_two .service_part .service_contract_text {
  margin-top: 0px;
  padding-top: 0px;
  border: none;
}

.choose_two .service_part .service_contract_text h4 {
  font-size: 32px;
}

.choose_two .service_part .service_contract_text p {
  margin-bottom: 40px;
}

.free_consultation {
  background: #ebf1f4;
  margin-left: -51px;
  border: 1px solid #dcdcdc;
  overflow: hidden;
}

.free_consultation .title_top {
  background: linear-gradient(to bottom, #ff9400, #febe00 /*#febe00*/);
  padding: 0px 15px;
  text-align: center;
  color: #fff;
  padding-top: 20px;
  position: relative;
  padding-bottom: 15px;
}

.free_consultation .title_top:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 210.5px 0 210.5px;
  border-color: #febe00 transparent transparent transparent;
  position: absolute;
  left: 0px;
  bottom: -25px;
}

.free_consultation .title_top h5 {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.free_consultation .title_top h3 {
  font-size: 26px;
  margin-bottom: 0px;
}

.free_consultation .contact_us_form {
  padding: 0px 22px 50px 22px;
  margin-top: 70px;
  margin-left: -9px;
  margin-right: -9px;
}

.free_consultation .contact_us_form .col-lg-6, .free_consultation .contact_us_form .col-lg-12 {
  padding-left: 9px;
  padding-right: 9px;
}

.free_consultation .contact_us_form .form-group input {
  height: 55px;
}

.free_consultation .contact_us_form .form-group textarea {
  height: 132px;
}

.free_consultation .contact_us_form .form-group .submit_btn {
  display: block;
  width: 100%;
  background: #e43838;
  border-color: #e43838;
  height: 48px;
}

.free_consultation .contact_us_form .form-group .submit_btn:hover {
  background: #fff;
  color: #e43838;
}

/* End Choose Contract Area css
============================================================================================ */
/* Replacement Area css
============================================================================================ */
.replacement_area {
  border-top: 6px solid #cf3535;
}

.replacement_area .media .d-flex {
  padding-right: 86px;
}

.replacement_area .media .d-flex img {
  margin-top: -85px;
  max-height: 200px;
}

.replacement_area .media .media-body {
  vertical-align: middle;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.replacement_area .media .media-body h4 {
  color: #fff;
  font-size: 28px;
  margin-bottom: 13px;
}

.replacement_area .media .media-body h5 {
  margin-bottom: 0px;
  font-size: 28px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-style: italic;
  color: #fff;
}

.replacement_area .media .media-body h5 a {
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  color: #ff9400;
}

/* End Replacement Area css
============================================================================================ */
/* End Replacement Area css
============================================================================================ */
.finance_area {
  padding-bottom: 140px;
}

.finance_area .finance_inner h2 {
  color: #051d3e;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 22px;
}

.finance_area .finance_inner p {
  margin-bottom: 30px;
}

.finance_area .finance_inner .big {
  font-size: 20px;
  font-family: "Playfair Display", serif;
  font-weight: normal;
  margin-top: 50px;
}

.finance_area .finance_inner .big + .big {
  margin-top: 30px;
}

.finance_area .finance_inner img {
  margin-top: 50px;
}

/* End Replacement Area css
============================================================================================ */
/* Special Area css
============================================================================================ */
.specials_area {
  padding-bottom: 140px;
}

.specials_inner .coupons_list .main_title {
  margin-bottom: 15px;
}

.specials_inner .coupons_list .nav li a {
  position: relative;
  padding-left: 30px;
  line-height: 38px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #797979;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  display: block;
}

.specials_inner .coupons_list .nav li a img {
  position: absolute;
  left: 0px;
  top: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.specials_inner .coupons_list .nav li a:hover {
  color: #d63343;
}

.free_estimate {
  margin-top: 45px;
}

.free_estimate h3 {
  font-size: 20px;
  color: #051d3e;
  margin-bottom: 15px;
}

.free_estimate p {
  margin-bottom: 0px;
}

.free_es_item {
  border: 2px dashed #7d7d7d;
  margin-top: 50px;
  padding: 30px 0px;
}

.free_es_item .media .d-flex {
  max-width: 275px;
  width: 100%;
  text-align: center;
}

.free_es_item .media .d-flex img {
  display: block;
  margin: auto;
}

.free_es_item .media .media-body {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.free_es_item .media .media-body h4 {
  color: #d63343;
  font-size: 32px;
  margin-bottom: 15px;
}

.free_es_item .media .media-body p {
  line-height: 26px;
  font-size: 16px;
  color: #242424;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 15px;
}

.free_es_item .media .media-body h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: #797979;
  margin-bottom: 0px;
}

.free_es_item:nth-child(odd) .media .d-flex img {
  margin-bottom: -30px;
}

/* End Special Area css
============================================================================================ */
/* Tips Area css
============================================================================================ */
.tips_area .main_title {
  margin-bottom: 40px;
}

.tips_area .main_title h2 {
  margin-bottom: 20px;
}

.tips_list_inner {
  border-bottom: 1px solid #d6d6d6;
}

.tips_list_inner p {
  margin-top: 100px;
  font-size: 32px;
  line-height: 46px;
  color: #051d3e;
  font-family: "Playfair Display", serif;
  font-style: italic;
  max-width: 765px;
  padding-bottom: 90px;
}

.tips_artical {
  margin-bottom: 55px;
}

.tips_artical:last-child {
  margin-bottom: 0px;
}

.tips_artical h4 {
  color: #051d3e;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 15px;
}

.tips_artical .nav li {
  margin-bottom: 15px;
}

.tips_artical .nav li:last-child {
  margin-bottom: 0px;
}

.tips_artical .nav li a {
  font-size: 18px;
  color: #051d3e;
  font-family: "Poppins", sans-serif;
}

/* End Tips Area css
============================================================================================ */
/* Repair System Area css
============================================================================================ */

.repair_system_area::before {
  content: "";
  background-image: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/big-star.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0.15;
  overflow: hidden;
  z-index: 0;
}

.repair_system_area .center_title_big {
  position: relative;
  margin-bottom: 86px;
}

.repair_system_area .center_title_big:before {
  content: "";
  height: 2px;
  width: 96px;
  background: #176bc5;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -28px;
}

.rp_box_item {
  border: 1px solid #dbdbdb;
  text-align: center;
  border-radius: 3px;
  padding-top: 25px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rp_box_item h4 {
  font-size: 20px;
  color: #051d3e;
  margin-bottom: -7px;
}

.rp_box_item .hp-ladder {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}

.rp_box_item .hp-repairs {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}

.rp_box_item .hp-carpentry {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}

.rp_box_item .hp-drywall {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}

.rp_box_item .hp-installation {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}

.rp_box_item .hp-tiles {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}

.rp_box_item .hp-exterior {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}

.rp_box_item .hp-painting {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}

.rp_box_item .hp-maintenance {
  margin-bottom: 15px;
  max-width: 75px;
  height: 75px;
  width: 75px;
  background: linear-gradient(-120deg, #ff9400, #febe00);
}


/* ../ /tiles.svg */

.rp_box_item .icon {
  height: 50px;
  width: 50px;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin: 0px auto 0px;
  position: relative;
  bottom: -24px;
}

.rp_box_item h3 {
  color: #000;
}

.rp_box_item.service-detail-estimate .icon {
  width: 200px;
  height: 45px;
  background: white;
  border-radius: 0;
}

.rp_box_item .icon i {
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  color: #797979;
  line-height: 36px;
  margin-top: 5px;
  border: 1px solid #e6e6e6;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.rp_box_item:hover {
  -webkit-box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.13);
}

.rp_box_item:hover .icon i {
  background: #d63343;
  color: #fff;
  border-color: #d63343;
}

/* End Repair System Area css
============================================================================================ */
/* Family Air Area css
============================================================================================ */
.family_area {
  background-size: cover;
  position: relative;
  padding-bottom: 85px;
  z-index: 2;
}

.family_area:before {
  content: "";
  background: linear-gradient(to right, rgba(78, 34, 4, 0.849), transparent);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.family_area .family_air_text h4 {
  color: #fff;
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 20px;
}

.family_area .family_air_text h4 span {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: bold;
  position: relative;
}


.family_area .family_air_text p {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 35px;
}

.family_area .family_air_text .main_btn {
  background: #e43838;
  border-color: #e43838;
  text-transform: capitalize;
  line-height: 45px;
  border-radius: 22.5px;
}

.family_area .family_air_text .main_btn:hover {
  background: #fff;
  color: #e43838;
  border-color: #fff;
}

/* End Family Air Area css
============================================================================================ */
/* Experience Area css
============================================================================================ */
.experience_inner .experience_text {
  margin-right: 35px;
}

.experience_inner .experience_text h3 {
  font-size: 40px;
  color: #051d3e;
  line-height: 43px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.experience_inner .experience_text h5 {
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  font-family: "Open Sans", sans-serif;
  color: #ff9400;
  margin-bottom: 15px;
}

.experience_inner .experience_text p {
  margin-bottom: 40px;
}

.experience_inner .experience_text .ex_item_inner .media {
  position: relative;
}

.experience_inner .experience_text .ex_item_inner .media:before {
  content: "";
  height: 40px;
  width: 1px;
  background: #d9d9d9;
  position: absolute;
  right: 0px;
  top: 3px;
}

.experience_inner .experience_text .ex_item_inner .media .d-flex {
  padding-right: 16px;
}

.experience_inner .experience_text .ex_item_inner .media .media-body h4 {
  font-size: 16px;
  color: #333333;
}

.experience_inner .experience_text .ex_item_inner .media .media-body h6 {
  font-size: 15px;
  font-style: italic;
  color: #868686;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0px;
}

.experience_inner .experience_text .main_btn {
  line-height: 46px;
}

/* End Experience Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* About Area css
============================================================================================ */
.about_bar_area {
  border-bottom: 1px solid #dbdfe3;
  padding: 38px 0px 26px 0px;
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/ab-bar-bg.png) no-repeat scroll center bottom;
}

.about_bar_text h5 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-bottom: 8px;
}

.about_bar_text h4 {
  font-size: 24px;
  color: #082650;
  margin-bottom: 0px;
}

.about_bar_text .ab_bar_btn {
  margin-left: 50px;
  margin-top: 0px;
}

/* End About Area css
============================================================================================ */
/* About Text Area css
============================================================================================ */
.about_text_area.service_call_in .ab_text_inner .ab_text_item {
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.about_text_area.service_call_in .ab_text_inner .contract_discount {
  margin-top: 30px;
}

.ab_text_inner .ab_text_item {
  margin-bottom: 38px;
}

.ab_text_inner .ab_text_item h3 {
  font-size: 32px;
  color: #051d3e;
  margin-bottom: 22px;
}

.ab_text_inner .ab_text_item p {
  margin-bottom: 0px;
}

.ab_text_inner .ab_text_item_two h3 {
  color: #051d3e;
  font-size: 26px;
  margin-bottom: 16px;
}

.ab_list h4 {
  font-size: 22px;
  color: #051d3e;
}

.ab_list .nav li a {
  font-size: 16px;
  line-height: 35px;
  color: #051d3e;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.ab_list .nav li a:hover {
  color: #d63343;
}

.ab_list .ab_logo {
  vertical-align: middle;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0px auto;
}

.request_service_box {
  background: #3376ca;
  padding: 40px 30px;
}

.request_service_box h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 30px;
}

.request_service_box p {
  color: #fff;
  font-size: 17px;
  font-style: italic;
  margin-bottom: 20px;
}

.request_service_box .contact_us_form .form-group input {
  height: 40px;
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.request_service_box .contact_us_form .form-group input.placeholder {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.request_service_box .contact_us_form .form-group input:-moz-placeholder {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.request_service_box .contact_us_form .form-group input::-moz-placeholder {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.request_service_box .contact_us_form .form-group input::-webkit-input-placeholder {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.request_service_box .contact_us_form .form-group textarea {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
  height: 121px;
}

.request_service_box .contact_us_form .form-group textarea.placeholder {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.request_service_box .contact_us_form .form-group textarea:-moz-placeholder {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.request_service_box .contact_us_form .form-group textarea::-moz-placeholder {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.request_service_box .contact_us_form .form-group textarea::-webkit-input-placeholder {
  color: #989fa5;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

/* End About Text Area css
============================================================================================ */
/* EPA Area css
============================================================================================ */
.epa_area {
  background: linear-gradient(-120deg, #e6e6e6, #c7c7c7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/* Our Moto Area css
============================================================================================ */
.our_moto_area {
  overflow: hidden;
  background: linear-gradient(-120deg, #ff9400, #febe00);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.our_moto_area .left_side {
  width: calc(50% - 215px);
  overflow: hidden;
}

.our_moto_area .right_side {
  width: calc(50% + 215px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.our_moto_area .right_side .moto_text {
  margin-left: 65px;
  max-width: 730px;
  vertical-align: middle;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.our_moto_area .right_side .moto_text h5 {
  color: #fff;
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 18px;
}

.our_moto_area .right_side .moto_text p {
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  margin-bottom: 0px;
}

.our_moto_area .right_side .moto_text h4 {
  font-size: 26px;
  color: #fff;
  margin-bottom: 18px;
  margin-top: 45px;
}

.our_moto_area.moto_two .right_side .moto_text h5 span {
  font-weight: 300;
}

.our_moto_area.moto_two .right_side .moto_text h4 {
  font-weight: 300;
}

.our_moto_area.moto_video .left_side {
  position: relative;
  z-index: 5;
}

.our_moto_area.moto_video .left_side a {
  height: 116px;
  width: 116px;
  background: #bd2433;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  text-align: center;
  line-height: 145px;
  z-index: 3;
}

.our_moto_area.moto_video .left_side a:before {
  content: "";
  width: 116px;
  height: 116px;
  border: 1px solid #bd2433;
  position: absolute;
  left: 0;
  top: 0px;
  border-radius: 50%;
  -webkit-animation: runpulse 1.5s infinite linear;
  animation: runpulse 1.5s infinite linear;
  z-index: -1;
}

.our_moto_area.moto_video .left_side a:after {
  content: "";
  width: 116px;
  height: 116px;
  background: rgba(189, 36, 51, 0.8);
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  z-index: -1;
  -webkit-animation: runpulse 1.5s infinite linear;
  animation: runpulse 1.5s infinite linear;
}

.our_moto_area.moto_video .left_side a .play {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 32px;
  border-color: transparent transparent transparent #ffffff;
  display: inline-block;
}

/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* End Our Moto Area css
============================================================================================ */
/* Emergency Area css
============================================================================================ */
.emergency_call_area {
  background: linear-gradient(-120deg, #ff9400, #febe00);
  padding-top: 35px;
  padding-bottom: 25px;
}

.emergency_call_area .left h4 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}

.emergency_call_area .left h4 span {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: bold;
}

.emergency_call_area .left p {
  font-size: 20px;
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  color: #fff;
  margin-bottom: 0px;
}

.emergency_call_area .left p.no-italic {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: #fff;
  margin-bottom: 0px;
}

.emergency_call_area .right {
  vertical-align: middle;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.emergency_call_area .right h5 {
  color: #fff;
  background: #cf3535;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 0px;
  font-size: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 31px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.31);
}

/* End Emergency Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Repair Service Area css
============================================================================================ */
.repair_service_inner .col-lg-3:nth-child(3) .r_service_item h4 {
  padding-top: 7px;
  bottom: 11px;
}

.repair_service_inner .col-lg-3:nth-child(3) .r_service_item h4:before {
  top: -7px;
}

.r_service_item {
  position: relative;
  text-align: center;
}

.r_service_item h4 {
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 18px;
  text-align: center;
  font-size: 17px;
  color: #fff;
  text-transform: uppercase;
  z-index: 3;
  font-weight: 600;
  text-shadow: none;
}

.r_service_item h4:before {
  content: "";
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/repair-service/r-service-shap.png) no-repeat scroll center center;
  height: 59px;
  width: 218px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -25px;
  z-index: -1;
}

.gray_service {
  background: #edf0f1;
}

.service_raw_text {
  margin-bottom: 85px;
}

.service_raw_text h4 {
  font-size: 32px;
  color: #051d3e;
  line-height: 44px;
  margin-bottom: 0px;
}

.service_raw_text p {
  font-size: 22px;
  font-weight: 200;
  color: #051d3e;
  font-family: "Open Sans", sans-serif;
  padding-left: 35px;
  padding-right: 20px;
  line-height: 32px;
  margin-bottom: 0px;
  position: relative;
}

.service_raw_text p:before {
  content: "";
  height: 123px;
  width: 1px;
  background: #bfc6cd;
  position: absolute;
  left: 0px;
  top: 5px;
}

/* Repair Service Area css
============================================================================================ */
/* Main Service Area css
============================================================================================ */
.main_service_area {
  padding-bottom: 140px;
}

.main_service_inner {
  margin-bottom: -50px;
}

.m_service_item {
  margin-bottom: 50px;
  text-align: center;
}

.m_service_item .service_img {
  overflow: hidden;
  position: relative;
  z-index: 3;
}

.m_service_item .service_img img {
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.m_service_item .service_img:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}

.m_service_item .service_text {
  border: 1px solid #e5e5e5;
  padding: 25px 22px;
}

.m_service_item .service_text h4 {
  color: #051d3e;
  font-size: 22px;
  margin-bottom: 10px;
}

.m_service_item .service_text p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 14px;
  color: #000;
}

.m_service_item .service_text .more_btn {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.m_service_item:hover .service_img:before {
  opacity: 1;
}

.m_service_item:hover .service_img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/* End Main Service Area css
============================================================================================ */
/* Service Details Area css
============================================================================================ */
.s_details_inner {
  padding-left: 30px;
}

.s_details_inner .s_details_main img {
  margin-bottom: 50px;
}

.s_details_inner .s_details_main h4 {
  font-size: 26px;
  color: #051d3e;
  margin-bottom: 15px;
}

.s_details_inner .s_details_main p {
  margin-bottom: 30px;
}

.s_details_inner .s_details_main p:last-child {
  margin-bottom: 0px;
}

.s_details_main img {
  margin-bottom: 50px;
}

.s_details_main h4 {
  font-size: 26px;
  color: #051d3e;
  margin-bottom: 15px;
}

.s_details_main p {
  margin-bottom: 30px;
}

.s_details_main p:last-child {
  margin-bottom: 0px;
}

.maintence_column {
  margin-top: 50px;
  margin-bottom: 55px;
}

.maintence_column .maintence_text {
  padding-left: 10px;
  padding-right: 46px;
}

.maintence_column .maintence_text h4 {
  color: #051d3e;
  font-size: 24px;
  margin-bottom: 12px;
}

.maintence_column .maintence_text p {
  margin-bottom: 20px;
}

.maintence_column .maintence_text .nav li {
  margin-bottom: 7px;
}

.maintence_column .maintence_text .nav li:last-child {
  margin-bottom: 0px;
}

.maintence_column .maintence_text .nav li a {
  color: #051d3e;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.maintence_column .maintence_text .nav li:hover a {
  color: #d63343;
}

.maintence_column .maintence_text.p-0 {
  padding-right: 46px !important;
}

.award_box .col-lg-4 {
  background: rgb(249, 249, 249);
  border-right: 1px solid #fff;
}

.award_box .col-lg-4:nth-child(2) {
  background: #fff;
}

.award_box .col-lg-4:last-child {
  background: rgb(249, 249, 249);
}

.award_box .award_box_item {
  padding: 30px 25px 40px 25px;
  text-align: center;
  color: #000;
}

.award_box .award_box_item img {
  margin-bottom: 20px;
}

.award_box .award_box_item h4 {
  font-size: 18px;
}

.award_box .award_box_item p {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 26px;
}

.award_box.gray_box .col-lg-4 {
  background: #fafafa;
  border: 1px solid #dbdbdb;
}

.award_box .col-lg-4 {
  border: 1px solid #ebebeb;
}

.award_box.gray_box .col-lg-4:first-child {
  border-left: 1px solid #e5e5e5;
}

.award_box.gray_box .col-lg-4:nth-child(2) {
  background: #fff;
}

.award_box.gray_box .award_box_item h4 {
  color: #242424;
}

.award_box.gray_box .award_box_item p {
  color: #797979;
}

.benefit_service {
  margin-top: 55px;
}

.benefit_service .main_title {
  margin-bottom: 40px;
}

.benefit_service .main_title h2 {
  font-size: 24px;
}

.benefit_service .nav {
  -webkit-columns: 2;
  columns: 2;
  width: 100%;
  display: block;
  margin-bottom: -10px;
}

.benefit_service .nav li {
  margin-bottom: 10px;
}

.benefit_service .nav li a {
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #999999;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  display: block;
}

.benefit_service .nav li a img {
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.benefit_service .nav li a:hover {
  color: #d63343;
}

.left_widget {
  margin-bottom: 50px;
}

.left_widget:last-child {
  margin-bottom: 0px;
}

.list_wd .nav {
  border-left: 3px solid #d63343;
  margin-bottom: 0px;
}

.list_wd .nav li {
  border-bottom: 3px solid #fff;
}

.list_wd .nav li:last-child {
  border-bottom: 0px;
}

.list_wd .nav li a {
  background: #f1f1f1;
  color: #242424;
  display: block;
  padding-left: 22px;
  line-height: 55px;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  position: relative;
}

.list_wd .nav li a:before {
  content: "";
  width: 14px;
  height: 1px;
  position: absolute;
  left: 22px;
  top: 50%;
  background: #fff;
  margin-top: -1px;
  opacity: 0;
}

.list_wd .nav li:hover a, .list_wd .nav li.active a {
  background: #ff9400;
  color: #fff;
  padding-left: 50px;
}

.list_wd .nav li:hover a:before, .list_wd .nav li.active a:before {
  opacity: 1;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.contact_wd {
  overflow: hidden;
  background: #d63343;
}

.contact_wd .text {
  background: #d63343;
  padding-left: 30px;
  padding-right: 25px;
  padding-bottom: 42px;
  position: relative;
  padding-top: 10px;
  text-align: center;
}

.contact_wd .text:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 20px 280px;
  border-color: transparent transparent #0f58a6 transparent;
  position: absolute;
  top: -56px;
  left: 0px;
  -webkit-transform: rotate(-14deg);
  -ms-transform: rotate(-14deg);
  transform: rotate(-14deg);
}

.contact_wd .text p {
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.contact_wd .text p span {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  display: block;
}

.contact_wd .text h4 {
  font-weight: bold;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  margin-bottom: 25px;
  text-align: center;
}

.contact_wd .text .main_btn {
  padding: 0px 30px;
  line-height: 44px;
}

.l_title h3 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #242424;
  margin-bottom: 25px;
}

.dw_wd .download_btn {
  display: block;
  background: #242424;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 14px 20px;
}

/* End Service Details Area css
============================================================================================ */
/* Repair Service Area css
============================================================================================ */
.repair_service_area {
  background: #e6ecf2;
  overflow: hidden;
}

.repair_service_area.bg_shadow {
  background: #f3f3f6;
}

.repair_service_area .repair_img {
  overflow: hidden;
}

.repair_service_area .rp_text {
  max-width: 499px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.repair_service_area .rp_text h4 {
  font-size: 50px;
  font-weight: bold;
  color: #051d3e;
  margin-bottom: 27px;
}

.repair_service_area .rp_text h6 {
  font-size: 20px;
  font-weight: 500;
  color: #051d3e;
  margin-bottom: 0px;
}

.repair_service_area .rp_text h5 {
  font-size: 22px;
  font-weight: 300;
  color: #242424;
  font-family: "Lato", sans-serif;
  margin-bottom: 15px;
}

.repair_service_area .rp_text p {
  font-size: 17px;
  line-height: 26px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  margin-bottom: 37px;
}

.repair_service_area .rp_text .main_btn {
  text-transform: none;
  line-height: 54px;
  padding: 0px 45px;
  border-radius: 27px;
  font-size: 16px;
}

/* End Repair Service Area css
============================================================================================ */
/* Service Slider Area css
============================================================================================ */
.service_slider_area {
  background: #0e2a4e;
}

.service_slider_area .main_title {
  position: relative;
  margin-bottom: 75px;
}

.service_slider_area .main_title:before {
  content: "";
  height: 1px;
  width: 87px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 50%;
  bottom: -18px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.service_slider_area .main_title p {
  color: rgba(255, 255, 255, 0.5);
}

.service_slider_area .service_slider {
  margin-bottom: -50px;
}

.service_slider_area .service_slider .m_service_item {
  background: #fff;
}

.service_slider_area .service_slider .service_arrow {
  text-align: center;
  margin-bottom: 50px;
}

.service_slider_area .service_slider .service_arrow .owl-prev {
  margin-right: 20px;
}

.service_slider_area .service_slider .service_arrow .owl-prev:before {
  content: '';
  width: 70px;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: -90px;
  top: 50%;
}

.service_slider_area .service_slider .service_arrow .owl-next:before {
  content: '';
  width: 70px;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: -90px;
  top: 50%;
}

.service_slider_area .service_slider .service_arrow .owl-prev, .service_slider_area .service_slider .service_arrow .owl-next {
  height: 44px;
  width: 44px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid #a7afb6;
  color: #a7afb6;
  font-size: 22px;
  padding: 0px;
  text-align: center;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  position: relative;
}

.service_slider_area .service_slider .service_arrow .owl-prev i, .service_slider_area .service_slider .service_arrow .owl-next i {
  line-height: 36px;
}

.service_slider_area .service_slider .service_arrow .owl-prev:hover, .service_slider_area .service_slider .service_arrow .owl-next:hover {
  background: #d63343;
  border-color: #d63343;
  color: #fff;
}

/* End Service Slider Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Company Video Area css
============================================================================================ */
.company_video_area {
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/company-bg.jpg) no-repeat scroll center center;
  background-size: cover;
  padding: 115px 0px 120px 0px;
}

.company_text {
  margin-right: 90px;
}

.company_text .company_text_part {
  margin-bottom: 45px;
}

.company_text .company_text_part:last-child {
  margin-bottom: 0px;
}

.company_text .company_text_part:last-child h4 {
  margin-bottom: 8px;
}

.company_text .company_text_part h4 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 18px;
}

.company_text .company_text_part p {
  line-height: 26px;
  color: #caddf5;
  margin-bottom: 0px;
}

.youtube_video {
  height: 100%;
  position: relative;
}

.youtube_video:before {
  content: "";
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -15px;
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/video-shadow.png) no-repeat scroll center center;
  position: absolute;
  height: 33px;
  width: 543px;
}

.youtube_video iframe {
  width: 100%;
  min-height: 354px;
  border-radius: 0px;
  border: none;
}

/* End Company Video Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* New Blog Area css
============================================================================================ */
.new_blog_area {
  background: #1a4c8c;
  padding: 80px 0px 100px 0px;
}

.new_blog_item .d-flex {
  overflow: hidden;
}

.new_blog_item .d-flex img {
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.new_blog_item .media-body {
  background: #e2e9f2;
  padding: 25px 40px 25px 30px;
  height: 100%;
}

.new_blog_item .media-body h6 {
  color: #818181;
  font-size: 13px;
  margin-bottom: 12px;
}

.new_blog_item .media-body h4 {
  font-size: 18px;
  color: #242424;
  margin-bottom: 13px;
}

.new_blog_item .media-body p {
  font-size: 15px;
  line-height: 24px;
  color: #797979;
  margin-bottom: 4px;
}

.new_blog_item:hover .d-flex img {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
}

/* End New Blog Area css
============================================================================================ */
/* Main Blog Area css
============================================================================================ */
.blog_area {
  padding-bottom: 140px;
}

.blog_list {
  padding-right: 30px;
}

.blog_item {
  margin-bottom: 75px;
}

.blog_item:last-child {
  margin-bottom: 0px;
}

.blog_item .blog_img {
  margin-bottom: 35px;
  overflow: hidden;
  position: relative;
  z-index: 3;
  display: block;
}

.blog_item .blog_img:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}

.blog_item .blog_img img {
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.blog_item .blog_text h4 {
  font-size: 28px;
  color: #051d3e;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 0px;
  padding-bottom: 12px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.blog_item .blog_text .nav {
  margin-bottom: 22px;
  margin-top: 14px;
}

.blog_item .blog_text .nav li {
  margin-right: 20px;
}

.blog_item .blog_text .nav li a {
  color: #b8b8b8;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.blog_item .blog_text .nav li a i {
  padding-right: 10px;
  color: #3a81af;
  font-size: 16px;
}

.blog_item .blog_text .nav li a:hover {
  color: #3a81af;
}

.blog_item .blog_text .nav li:last-child {
  margin-right: 0px;
}

.blog_item .blog_text p {
  margin-bottom: 25px;
}

.blog_item:hover .blog_img:before {
  opacity: 1;
}

.blog_item:hover .blog_img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.blog_item:hover .blog_text h4 {
  color: #d63343;
}

.pagination_area {
  margin-top: 50px;
  margin-bottom: 0px;
}

.pagination_area .pagination {
  border: none;
  border-radius: 0px;
  margin-bottom: 0px;
}

.pagination_area .pagination li {
  margin-right: 10px;
}

.pagination_area .pagination li a {
  border-radius: 0px !important;
  padding: 0px;
  width: 44px;
  height: 44px;
  border: 2px solid #d4d4d4;
  text-align: center;
  line-height: 40px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #242424;
}

.pagination_area .pagination li:hover a, .pagination_area .pagination li.active a {
  background: #13213b;
  border-color: #13213b;
  color: #fff;
}

.r_widget {
  margin-bottom: 40px;
}

.r_title {
  margin-bottom: 20px;
}

.r_title h3 {
  color: #051d3e;
  margin-bottom: 0px;
  font-size: 24px;
}

.categories_widget .list {
  margin-top: -5px;
}

.categories_widget .list li a {
  display: block;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #797979;
  line-height: 42px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.categories_widget .list li a i {
  float: right;
  line-height: 42px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  left: 0px;
  position: relative;
  color: #c8c8c8;
}

.categories_widget .list li:last-child a {
  border-bottom: 0px;
}

.categories_widget .list li:hover a {
  color: #d63343;
}

.categories_widget .list li:hover a i {
  position: relative;
  left: -4px;
}

.search_widget .input-group {
  display: block;
}

.search_widget .input-group input {
  width: 100%;
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 52px;
  font-size: 15px;
  color: #7a7a7a;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #d3d3d3;
  padding: 0px 50px 0px 20px;
}

.search_widget .input-group input.placeholder {
  font-size: 15px;
  color: #7a7a7a;
  font-family: "Open Sans", sans-serif;
}

.search_widget .input-group input:-moz-placeholder {
  font-size: 15px;
  color: #7a7a7a;
  font-family: "Open Sans", sans-serif;
}

.search_widget .input-group input::-moz-placeholder {
  font-size: 15px;
  color: #7a7a7a;
  font-family: "Open Sans", sans-serif;
}

.search_widget .input-group input::-webkit-input-placeholder {
  font-size: 15px;
  color: #7a7a7a;
  font-family: "Open Sans", sans-serif;
}

.search_widget .input-group .input-group-append {
  position: absolute;
  right: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
}

.search_widget .input-group .input-group-append .btn {
  padding: 0px 14px;
  margin-left: 0px;
  background: #e3ebf0;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0px;
  line-height: 48px;
  display: inline-block;
  border-left: 1px solid #d3d3d3;
}

.search_widget .input-group .input-group-append .btn i {
  font-size: 16px;
  color: #414141;
}

.tags_widget .list {
  margin-right: -7px;
  margin-bottom: -10px;
}

.tags_widget .list li {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 10px;
}

.tags_widget .list li a {
  border: 1px solid #e4e4e4;
  font-size: 15px;
  color: #a6a6a6;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  padding: 0px 15px;
  line-height: 36px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.tags_widget .list li:hover a {
  color: #fff;
  background: #d63343;
  border-color: #d63343;
}

.recent_widget .recent_w_inner .recent_item {
  border-bottom: 1px solid #e1e1e1;
}

.recent_widget .recent_w_inner .recent_item h4 {
  font-size: 15px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #242424;
  padding-top: 17px;
}

.recent_widget .recent_w_inner .recent_item p {
  font-size: 15px;
  color: #9a9a9a;
  font-family: "Poppins", sans-serif;
}

.recent_widget .recent_w_inner .recent_item:first-child h4 {
  padding-top: 0px;
}

.single_blog_inner .blog_item {
  margin-bottom: 36px;
}

.two_column_text {
  margin-bottom: 50px;
}

.two_column_text p {
  margin-bottom: 0px;
}

.b_single_text {
  color: #242424;
  font-size: 22px;
  margin-bottom: 25px;
}

.single_element_text p a {
  color: #d63343;
  text-decoration: underline;
  font-family: "Open Sans", sans-serif;
}

.single_title {
  margin-bottom: 40px;
}

.single_title h3 {
  font-size: 22px;
  color: #051d3e;
}

.s_comment_list {
  margin-top: 45px;
}

.s_comment_list .s_comment_list_inner {
  border: 1px solid #eeeeee;
}

.s_comment_list .s_comment_list_inner .media {
  padding-top: 25px;
  padding-bottom: 28px;
  padding-left: 25px;
  padding-right: 75px;
  margin-top: 0px;
  border-bottom: 1px solid #eeeeee;
}

.s_comment_list .s_comment_list_inner .media .d-flex {
  padding-right: 30px;
}

.s_comment_list .s_comment_list_inner .media .media-body h4 {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  letter-spacing: .45px;
  line-height: normal;
  color: #242424;
  font-weight: 500;
  margin-bottom: 8px;
}

.s_comment_list .s_comment_list_inner .media .media-body p {
  margin-bottom: 0px;
}

.s_comment_list .s_comment_list_inner .media .media-body .date_rep {
  margin-top: 7px;
}

.s_comment_list .s_comment_list_inner .media .media-body .date_rep a {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: .39px;
  color: #aeaeae;
  margin-right: 20px;
  position: relative;
}

.s_comment_list .s_comment_list_inner .media .media-body .date_rep a:before {
  content: "";
  background: #aeaeae;
  height: 13px;
  width: 1px;
  position: absolute;
  right: -12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.s_comment_list .s_comment_list_inner .media .media-body .date_rep a:last-child {
  color: #d63343;
}

.s_comment_list .s_comment_list_inner .media .media-body .date_rep a:last-child:before {
  display: none;
}

.s_comment_list .s_comment_list_inner .media .media-body .media {
  background: transparent;
  padding-left: 0px;
  padding-top: 40px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.s_comment_list .s_comment_list_inner .media:nth-child(even) {
  background: #fcfcfc;
}

.s_comment_list .s_comment_list_inner .media:last-child {
  border-bottom: none;
}

.s_comment_area {
  margin-top: 60px;
}

.s_comment_area .s_comment_inner {
  background: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 50px 35px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group {
  margin-bottom: 20px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group input {
  background: #fff;
  font-size: 16px;
  color: #737373;
  height: 45px;
  border: 1px solid #eeeeee;
  border-radius: 0px;
  padding: 0px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  line-height: 45px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group input.placeholder {
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
  line-height: 45px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group input:-moz-placeholder {
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
  line-height: 45px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group input::-moz-placeholder {
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
  line-height: 45px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group input::-webkit-input-placeholder {
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
  line-height: 45px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group textarea {
  background: #fff;
  border-color: #eeeeee;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 172px;
  padding: 15px 20px;
  border-radius: 0px;
  resize: none;
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group textarea.placeholder {
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group textarea:-moz-placeholder {
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group textarea::-moz-placeholder {
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group textarea::-webkit-input-placeholder {
  font-size: 16px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group:last-child {
  margin-top: 10px;
  margin-bottom: 0px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group .submit_btn {
  display: inline-block;
  width: auto;
  color: #fff;
  line-height: 42px;
  height: 42px;
  padding: 0px 20px;
}

.s_comment_area .s_comment_inner .contact_us_form .form-group .submit_btn:hover {
  color: #d63343;
}

/* End Main Blog Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Client Service Area css
============================================================================================ */
.client_service_area {
  padding: 70px 0px 60px 0px;
}

.client_service_area.tips_client {
  padding: 90px 0px 120px 0px;
}

.client_service_area.client_logo {
  padding-top: 100px;
  padding-bottom: 130px;
}

.client_slider .item {
  text-align: center;
  display: block;
  line-height: 100px;
}

.client_slider .item img {
  width: auto;
  display: inline-block;
  text-align: center;
  line-height: 100px;
}

/* Client Service Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Team Area css
============================================================================================ */
.our_team_area {
  background: #f7f7f7;
}

.our_team_area.pad_top {
  padding-bottom: 140px;
}

.our_team_area.our_team2 {
  background: #22335d;
}

.our_team_inner {
  margin-bottom: -60px;
}

.team_item {
  margin-bottom: 60px;
}

.team_item .team_img {
  position: relative;
  overflow: hidden;
  z-index: 3;
}

.team_item .team_img img {
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.team_item .team_img:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(189, 36, 51, 0.9);
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}

.team_item .team_img .nav {
  position: absolute;
  left: 0px;
  bottom: 35px;
  text-align: center;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team_item .team_img .nav li {
  margin-right: 10px;
}

.team_item .team_img .nav li:last-child {
  margin-right: 0px;
}

.team_item .team_img .nav li a {
  display: inline-block;
  height: 35px;
  width: 35px;
  border: 1px solid #fff;
  color: #fff;
  line-height: 35px;
  border-radius: 50%;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.team_item .team_img .nav li:hover a {
  color: #242424;
  border-color: #242424;
}

.team_item .team_text {
  background: #fff;
  max-width: 238px;
  margin: auto;
  text-align: center;
  padding: 12px 10px 15px 10px;
  margin-top: -20px;
  position: relative;
  z-index: 5;
}

.team_item .team_text h4 {
  margin-bottom: 0px;
  font-size: 18px;
  color: #242424;
  margin-bottom: 5px;
}

.team_item .team_text h5 {
  font-family: "Lato", sans-serif;
  color: #797979;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0px;
}

.team_item:hover .team_img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.team_item:hover .team_img:before {
  opacity: 1;
}

.team_item:hover .team_img .nav {
  opacity: 1;
  bottom: 58px;
  z-index: 2;
}

/* End Team Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Testimonials Area css
============================================================================================ */
.c_testi_slider {
  margin-top: -25px;
  margin-bottom: -50px;
  padding-left: 15px;
  padding-right: 15px;
}

.c_testi_slider .item {
  padding: 25px;
}

.c_testi_slider .item .b_testi {
  -webkit-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.13);
  padding: 25px 30px;
  background: #fff;
}

.testimonials_area {
  background: #fff;
}

.testimonials_area.pad_top {
  padding-bottom: 140px;
}

.testimonials_area .main_title {
  margin-bottom: 100px;
}

.testimonials_area .main_title h2 {
  margin-bottom: 20px;
}

.testimonials_inner {
  margin-bottom: -105px;
}

.text_item_box {
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/breadcrumb-banner.png) no-repeat scroll center center;
  background-size: cover;
  max-width: 330px;
  text-align: center;
  padding: 0px 30px;
  margin-bottom: 105px;
  position: relative;
  padding-bottom: 75px;
  color: #fff;
}

.text_item_box img {
  margin-top: -48px;
}

.text_item_box h3 {
  margin-top: 20px;
  font-size: 16px;
  color: #242424;
  margin-bottom: 7px;
}

.text_item_box h5 {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  color: #fff;
  margin-bottom: 20px;
  font-weight: normal;
}

.text_item_box p {
  margin-bottom: 0px;
}

.text_item_box i {
  font-size: 130px;
  color: #ff9400;
  font-family: Arial;
  font-weight: bold;
  font-style: normal;
  line-height: auto;
  position: absolute;
  bottom: 0px;
  right: 20px;
}

/* End Testimonials Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Contact Area css
============================================================================================ */
.contact_details_area .main_title h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}

.contact_details_text .media {
  margin-bottom: 14px;
}

.contact_details_text .media:last-child {
  margin-bottom: 0px;
}

.contact_details_text .media .d-flex {
  max-width: 52px;
  width: 100%;
}

.contact_details_text .media .media-body h4 {
  color: #242424;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
}

.contact_details_text .media .media-body a {
  color: #797979;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.contact_details_text .media .media-body p {
  margin-bottom: 0px;
}

.contact_form_area h3 {
  color: #051d3e;
  font-size: 34px;
  margin-bottom: 35px;
}

.contact_us_form .form-group {
  margin-bottom: 20px;
}

.contact_us_form .form-group:last-child {
  margin-bottom: 0px;
}

.contact_us_form .form-group input {
  height: 55px;
  padding: 0px 20px;
  border-radius: 0px;
  border: 1px solid #e1e1e1;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group input.placeholder {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group input:-moz-placeholder {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group input::-moz-placeholder {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group input:focus {
  border-color: #d63343;
}

.contact_us_form .form-group textarea {
  height: 230px;
  padding: 15px 20px;
  border-radius: 0px;
  border: 1px solid #e1e1e1;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
  resize: none;
}

.contact_us_form .form-group textarea.placeholder {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group textarea:-moz-placeholder {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group textarea::-moz-placeholder {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group textarea::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #797979;
}

.contact_us_form .form-group textarea:focus {
  border-color: #d63343;
}

.contact_us_form .form-group .submit_btn {
  margin-top: 20px;
  padding: 0px 28px;
  line-height: 44px;
}

.map_area .mapBox2 {
  min-height: 425px;
}

/* End Contact Area css
============================================================================================ */
/* Faq Area css
============================================================================================ */
.faq_area .main_title {
  margin-bottom: 60px;
}

.faq_area .main_title h2 {
  font-size: 32px;
  margin-bottom: 23px;
}

.faq_inner .faq_title h3 {
  color: #051d3e;
  font-size: 32px;
  position: relative;
  margin-bottom: 65px;
}

.faq_inner .faq_title h3:before {
  content: "";
  width: 40px;
  height: 2px;
  background: #adadad;
  position: absolute;
  left: 0px;
  bottom: -12px;
}

.faq_inner .faq_title h3 span {
  font-family: "Playfair Display", serif;
  font-style: italic;
}

.faq_inner #accordionExample {
  padding-right: 65px;
}

.faq_inner .accordion .card {
  border: none;
  margin-top: 46px;
}

.faq_inner .accordion .card .card-header {
  border: none;
  padding: 0px;
  background: transparent !important;
}

.faq_inner .accordion .card .card-header button {
  padding: 0px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #000;
  text-align: left;
  padding-left: 28px;
  padding-right: 20px;
  text-decoration: none;
}

.faq_inner .accordion .card .card-header button i {
  position: absolute;
  left: 0px;
  top: 1px;
  font-style: normal;
  display: none;
}

.faq_inner .accordion .card .card-header button i + i {
  display: inline-block;
}

.faq_inner .accordion .card .card-header button.collapsed i {
  display: inline-block;
}

.faq_inner .accordion .card .card-header button.collapsed i + i {
  display: none;
}

.faq_inner .accordion .card .card-body {
  padding: 15px 20px 0px 28px;
}

.faq_inner .accordion .card:first-child {
  margin-top: 0px;
}

.question_form_area {
  background: #072d5b;
  padding-bottom: 145px;
}

.question_form_area h3 {
  color: #fff;
  margin-bottom: 45px;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.question_form_area h3:before {
  content: "";
  position: absolute;
  right: -80px;
  bottom: 7px;
  width: 40px;
  height: 2px;
  background: #fff;
}

.question_form_area .contact_us_form .form-group {
  margin-bottom: 25px;
}

.question_form_area .contact_us_form .form-group:last-child {
  margin-bottom: 0px;
}

.question_form_area .contact_us_form .form-group input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #335176;
  padding: 0px;
  height: 62px;
  color: rgba(255, 255, 255, 0.7);
}

.question_form_area .contact_us_form .form-group input.placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.question_form_area .contact_us_form .form-group input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.question_form_area .contact_us_form .form-group input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.question_form_area .contact_us_form .form-group input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.question_form_area .contact_us_form .form-group textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid #335176;
  padding: 15px 0px;
  height: 160px;
}

.question_form_area .contact_us_form .form-group textarea.placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.question_form_area .contact_us_form .form-group textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.question_form_area .contact_us_form .form-group textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.question_form_area .contact_us_form .form-group textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

/* End Faq Area css
============================================================================================ */
/* Appiontment Area css
============================================================================================ */
.ap_title {
  margin-top: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #242424;
  line-height: 1.2;
  letter-spacing: 0.3px;
  font-size: 16px;
}

.ap_title span {
  color: #d63343;
}

.appointment_inner > .row {
  margin-bottom: 0px;
}

.appointment_inner div {
  margin-bottom: 20px;
}

.appointment_inner .col-lg-9 {
  margin-bottom: 0px;
}

.appointment_inner .appointment-form {
  background: #f0f0f0;
  padding: 60px 40px;
}

.appointment_inner .appointment-form .submit_btn {
  margin-top: 30px;
  padding: 0px 35px;
}

.appointment_inner .service_chose {
  width: 100%;
  display: inline-block;
}

.appointment_inner .service_chose .ga-radio {
  display: none;
}

.appointment_inner .service_chose .ga-radio + label {
  float: left;
  font: 400 15px/26px "Open Sans", sans-serif;
  letter-spacing: 0.3px;
  color: #898989;
  padding-left: 30px;
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/check-box.jpg) no-repeat scroll left 8px;
  margin: 0 30px 0 0;
  max-width: 630px;
  cursor: pointer;
}

.appointment_inner .service_chose .ga-radio:checked + label {
  background-image: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/check-box-checked.jpg);
}

.appointment_inner .day_select {
  width: 100%;
}

.appointment_inner .day_select .nice_select {
  width: 100%;
  border-radius: 0px;
}

.appointment_inner .day_select .nice_select .list {
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
  border-radius: 0px;
}

.appointment_inner .time_sl {
  display: block;
}

.appointment_inner .time_sl .nice_select {
  width: 200px;
  border-radius: 0px;
}

.appointment_inner .time_sl .nice_select .list {
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
  border-radius: 0px;
}

.appointment_inner .form_box_1 {
  width: 100%;
  margin: 0px;
}

.appointment_inner .form_box_1 .form-group {
  padding-left: 0px;
  padding-right: 0px;
}

.appointment_inner .form_box_1 .form-group input {
  height: 50px;
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

.appointment_inner .form_box_1 .form-group input.placeholder {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

.appointment_inner .form_box_1 .form-group input:-moz-placeholder {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

.appointment_inner .form_box_1 .form-group input::-moz-placeholder {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

.appointment_inner .form_box_1 .form-group input::-webkit-input-placeholder {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

.appointment_inner .form_box_1 .form-group input + input {
  margin-top: 20px;
}

.appointment_inner .form_box_1 .form-group textarea {
  height: 200px;
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
  resize: none;
}

.appointment_inner .form_box_1 .form-group textarea.placeholder {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

.appointment_inner .form_box_1 .form-group textarea:-moz-placeholder {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

.appointment_inner .form_box_1 .form-group textarea::-moz-placeholder {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

.appointment_inner .form_box_1 .form-group textarea::-webkit-input-placeholder {
  font-size: 16px;
  color: #242424;
  font-family: "Open Sans", sans-serif;
}

/* End Appiontment Area css
============================================================================================ */
/* Contact Success and error Area css
============================================================================================ */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) !important;
  -ms-transform: translateX(-50%) translateY(-50%) !important;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

.modal-message .modal-dialog .modal-content {
  border: none;
}

.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  color: #d63343;
  padding-bottom: 5px;
  font-family: "Playfair Display", serif;
}

.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/* End Contact Success and error Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Gallery Area css
============================================================================================ */
.gallery_area {
  padding-bottom: 140px;
}

.img-fluid {
  width: 100%;
}

.gallery_area.blue_gallery {
  padding-bottom: 100px;
  background: #fff;
  position: relative;
}

.gallery_area.blue_gallery .single_title {
  margin-bottom: 0px;
  margin-bottom: 45px;
}

.gallery_area.blue_gallery .single_title h2 {
  font-size: 34px;
}

.gallery_area.blue_gallery .g_fillter {
  background: transparent;
  margin-bottom: 0px;
}

.gallery_area.blue_gallery .g_fillter .nav li a {
  color: #e43838;
  line-height: 40px;
}

.gallery_area.blue_gallery .g_fillter .nav li a:before {
  background: #fff;
}

.g_fillter {
  background: #f3f3f6;
  margin-bottom: 50px;
}

.g_fillter .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.g_fillter .nav li {
  margin-right: 30px;
}

.g_fillter .nav li:last-child {
  margin-right: 0px;
}

.g_fillter .nav li a {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 62px;
  color: #242424;
  position: relative;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.g_fillter .nav li a:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -1px;
  height: 1px;
  width: 100%;
  background: #2760ca;
  opacity: 0;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.g_fillter .nav li:hover a, .g_fillter .nav li.active a {
  color: #2760ca;
}

.g_fillter .nav li:hover a:before, .g_fillter .nav li.active a:before {
  opacity: 1;
}

.gallery_inner {
  margin-bottom: -30px;
}

.gallery_item {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.gallery_item a {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 3;
}

.homePage .gallery_item a {
  max-height: 550px;
}

.gallery_item a img {
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.gallery_item a:before {
  content: "";
  background: rgba(189, 36, 51, 0.89);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}

.gallery_item a i {
  position: absolute;
  left: 50%;
  top: 50%;
  background: rgba(0, 0, 0, 0.27);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  line-height: 50px;
  margin-left: -25px;
  margin-top: -35px;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  opacity: 0;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  cursor: pointer;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.27);
}

.gallery_item a h4 {
  position: absolute;
  left: 0px;
  bottom: 30px;
  color: #fff;
  opacity: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  z-index: 2;
  padding-left: 70px;
}

.gallery_item a h4:before {
  content: "";
  width: 30px;
  height: 1px;
  position: absolute;
  left: 22px;
  background: #fff;
  bottom: 5px;
}

.gallery_item a:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.gallery_item a:hover i {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.gallery_item a:hover h4 {
  opacity: 1;
}

.gallery_item a:hover:before {
  opacity: 1;
}

/* End Gallery Area css
============================================================================================ */
/* Home Gallery Area css
============================================================================================ */
.home_gallery_slider .gallery_item {
  margin-bottom: 0px;
}

/* End Home Gallery Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Search Form Animation Area css
============================================================================================ */
.search_area {
  position: relative;
  max-width: 950px;
  margin: auto;
}

.search_area .search_box_inner {
  background: #f0f2f5;
  padding: 70px 60px 100px 60px;
  display: block;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  border-color: #d7d9dd;
}

.search_area .search_box_inner h3 {
  padding-bottom: 40px;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  color: #051d3e;
  letter-spacing: .48px;
}

.search_area .search_box_inner .input-group {
  display: block;
  position: relative;
}

.search_area .search_box_inner .input-group input {
  background: transparent;
  border-radius: 0px;
  outline: none;
  height: 50px;
  padding: 0px;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #b9bec4;
  color: #242424;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  width: 100%;
}

.search_area .search_box_inner .input-group input.placeholder {
  color: #242424;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: normal;
}

.search_area .search_box_inner .input-group input:-moz-placeholder {
  color: #242424;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: normal;
}

.search_area .search_box_inner .input-group input::-moz-placeholder {
  color: #242424;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: normal;
}

.search_area .search_box_inner .input-group input::-webkit-input-placeholder {
  color: #242424;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: normal;
}

.search_area .search_box_inner .input-group .input-group-btn {
  position: absolute;
  right: 5px;
  top: 12px;
  z-index: 4;
  display: none;
}

.search_area .search_box_inner .input-group .input-group-btn .btn-default {
  padding: 0px;
  border: none;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent;
  color: #666;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: .28px;
}

.search_area .mfp-close {
  right: 0px;
  color: #242424;
  opacity: 1;
}

/* Styles for dialog window */
#small-dialog {
  background: white;
  padding: 20px 30px;
  text-align: left;
  max-width: 400px;
  margin: 40px auto;
  position: relative;
}

/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
 * Fade-move animation for second dialog
 */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* End Search Form Animation Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Footer Area css
============================================================================================ */
.footer_widgets {
  background: #0b1529;
  padding-top: 70px;
}

.f_title {
  margin-bottom: 20px;
}

.f_title h3 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 20px;
}

.contact_widget .footer_address p {
  color: #9ba8c0;
  font-size: 16px;
  line-height: 26px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 6px;
}

.contact_widget .footer_address p a {
  font-size: 16px;
  line-height: 26px;
  font-family: "Open Sans", sans-serif;
  color: #9ba8c0;
}

.link_widget {
  margin-left: -25px;
  max-width: 360px;
}

.link_widget .nav {
  display: block;
  -webkit-columns: 2;
  columns: 2;
  margin-bottom: -7px;
}

.link_widget .nav li {
  margin-bottom: 7px;
}

.link_widget .nav li a {
  font-size: 16px;
  color: #9ba8c0;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.link_widget .nav li:hover a {
  color: #d63343;
}

.link_widget .nav li:last-child a {
  line-height: 20px;
  display: inline-block;
}

.payment_widget {
  margin-left: -42px;
}

.payment_widget .media .d-flex {
  padding-right: 30px;
}

.payment_widget .media .media-body {
  vertical-align: middle;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.payment_widget .media .media-body h4 {
  color: #fff;
  font-size: 24px;
  line-height: 34px;
  font-style: italic;
  margin-bottom: 0px;
}

.payment_widget h5 {
  color: #fff;
  padding-left: 45px;
  margin-bottom: 0px;
  margin-top: 50px;
  font-size: 20px;
}

.payment_widget h5 img {
  padding-left: 20px;
}

.footer_copyright {
  background: #091222;
  padding: 28px 0px;
}

.footer_copyright .left p {
  margin-bottom: 0px;
  font-size: 16px;
  color: rgba(155, 168, 192, 0.5);
  font-weight: normal;
}

.footer_copyright .left p a {
  color: rgba(155, 168, 192, 0.5);
}

.footer_copyright .right a {
  color: rgba(155, 168, 192, 0.5);
  padding-right: 20px;
  font-size: 16px;
  position: relative;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.footer_copyright .right a:before {
  content: '|';
  position: absolute;
  right: 3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(155, 168, 192, 0.5);
}

.footer_copyright .right a:last-child {
  padding-right: 0px;
}

.footer_copyright .right a:last-child:before {
  display: none;
}

.footer_copyright .right a:hover {
  color: #d63343;
}

.navbar-brand img {
  max-width: 160px;
}

.banner-stripe {
  background: linear-gradient(-120deg, #ff9400, #febe00);
  width: 100%;
  height: 10px;
  z-index: 10;
}

.menu_three.navbar_fixed .main_menu .banner-stripe {
  height: 8px;
}

.mr-0 {
  margin-right: 0;
}

.parent-company-banner {
  font-size: 0.7em;
  color: #cf3535;
  margin-top: -8px;
  font-weight: bold;
}

.tp-bgimg.defaultimg::after {
  content: "";
  background: linear-gradient(to right, rgba(78, 34, 4, 0.836), transparent);
  position: absolute;
  width: 100%;
  height: 900px;
}

.primary-cta {
  padding: 0px 19px;
  height: 35px;
  font-weight: 600;
  letter-spacing: 1px;
}

.flex-center-all {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.flex-direction-col {
  flex-direction: column;
}

.slogan_text {
  font-family: "Dancing Script";
  font-weight: 400;
}

.slogan-bold {
  font-weight: 700;
  display: block;
  font-family: "Dancing Script";
  font-size: inherit;
}

.footer-licenses {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: max-content;
  background: transparent;
  color: rgba(155, 168, 192, 0.5);
  padding-top: 65px;
}

.footer-licenses p {
  padding-left: 25px;
  padding-right: 25px;
}

.cta-footer-section img {
  max-width: 250px;
}

.replacement_area.cta-footer-section {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: url("https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/wood-bg.png");
  z-index: 1;
}

.cta-wood-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.7;
}

.replacement_area.cta-footer-section .container {
  position: relative;
  z-index: 3;
}

.ml-1em {
  margin-left: 1em;
}

.mt-mb-1em {
  margin-top: 1em;
  margin-bottom: 1em;
}

.p-1-5em {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bolt {
  max-width: 20px;
}

.referrer-accent {
  color: #e43838;
}

.family_area .family_air_text h4.referrer-main {
  line-height: 1.5em;
}

.family_area .family_air_text h4.referrer-main span {
  color: #e43838;
  font-family: inherit;
  font-style: inherit;
  font-weight: bold;
  position: relative;
  background: white;
  padding: 0 10px;
  border-radius: 50px;
}

a.hp-referral-link {
  color: #fff;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ml-35px {
  margin-left: 35px;
}

.ml-15px {
  margin-left: 15px;
}

.mt-2em {
  margin-top: 2em;
}

.hp-gallery-bg {
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  height: 100%;
  opacity: 1;
}

.hp-gallery-bg::after {
  content: "";
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
}

.w-100 {
  width: 100%;
}

.pt-2em {
  padding-top: 2em;
}

.primary-red {
  color: #cf3535;
}

.primary-orange {
  color: #ff9400;
}

.mb-100px {
  margin-bottom: 100px;
}

.p-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pb_100 {
  padding-bottom: 100px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pt_100 {
  padding-top: 100px;
}

.m-100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

section.red-bg {
  background: #cf3535;
}

.mt-50 {
  margin-top: 50px;
}

.main_btn.main_btn_border {
  border: #fff 2px solid;
}

.menu_three .main_menu .navbar .nav.navbar-nav.navbar-right li.search a:before {
  content: "";
  height: 0;
}

.menu_three .main_menu .navbar .nav.navbar-nav.navbar-right li.search div {
  position: relative;
}

.menu_three .main_menu .navbar .nav.navbar-nav.navbar-right li.search div {
  color: black;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 20px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
}

.menu_three .main_menu .navbar .nav.navbar-nav.navbar-right li.search div:before {
  content: "";
  height: 15px;
  width: 1px;
  color: white;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}

.main_menu .navbar .nav.navbar-nav li div:before {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAArCAYAAABSFVnaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDoyMjliNzg0ZS01MTNmLTE3NDctOWEyOC00YTUyNDk2OTc3MzYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MzQ1NEMyN0I4MzY3MTFFOTg5MENBNjE0MzVCNEY1MUMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MzQ1NEMyN0E4MzY3MTFFOTg5MENBNjE0MzVCNEY1MUMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZjNkNjNmNzUtYmI1ZS05ZDRkLWI0YmQtNWJjM2MwOWE4NzdmIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjIyOWI3ODRlLTUxM2YtMTc0Ny05YTI4LTRhNTI0OTY5NzczNiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PimLniUAAACGSURBVHjaZMzRCoJAEIXh1ZS0IMEIvfMde8SeRymlLgpznRn4ZWUvDvtxZnYO7f3RJEV3ypxzZ4lXVJJFUUv+imsIG91oan7FOxdQMSpZPkqcoqDJabII6Q5pCHuB7XhGC5c92JpZkgD79YvwBR/w1sOKieYFBkY9l3uaIWwMT36NilWAAQCMlyY2nf8J+QAAAABJRU5ErkJggg==) no-repeat scroll center center;
  height: 43px;
  width: 2px;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 1200px) {
  .main_menu .container {
      max-width: 1300px;
  }
}

.andersen-seal {
  position: absolute;
  bottom: 25px;
  right: 25px;
  background-image: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/Certified_Contractor_Square_Logo_4_Color_White_background_for_digital_.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 140px;
  width: 140px;
}

.velux-seal {
  position: absolute;
  bottom: -25px;
  right: 25px;
  background-image: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/vlux_certified.jpeg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 90px;
  width: 90px;
}


@media (min-width: 700px) {
  .velux-seal {
    height: 180px;
    width: 180px;
  }
}

.menu_three.navbar_fixed .main_menu img.andersen-menu-item {
  width: 160px;
  max-width: 160px;
}

img.andersen-menu-item {
  width: 160px;
  margin-bottom: 5px;
}

.andersen-banner-logo {
  height: 200px;
}

.velux-banner-logo {
  height: 150px;
}

.andersen-banner-left-container {
  display: inline;
}

.andersen-banner-left-container div {
  display: inline;
}

section.andersen-banner-area {
  position: relative;
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/andersen-banner-bg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
}

section.andersen-banner-area div, section.andersen-banner-area img {
  position: relative;
  z-index: 2;
}

section.andersen-banner-area h1, section.andersen-banner-area h4, section.andersen-banner-area p {
  color: #fff;
  z-index: 2;
}

section.andersen-banner-area:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-120deg, #f26924, #f26924 99%);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

section.andersen-banner-area.velux-banner-area:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-120deg, #cf353557, #cf3535 99%);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

section.andersen-banner-area.velux-banner-area {
  position: relative;
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/skylight-sample.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
}

section.andersen-banner-area div, section.andersen-banner-area img {
  position: relative;
  z-index: 2;
}

section.andersen-banner-area h1, section.andersen-banner-area h4, section.andersen-banner-area p {
  color: #fff;
  z-index: 2;
}

section.andersen-banner-area:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-120deg, #ff9400, #febe00a3);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

section.carpentry-banner-area {
  position: relative;
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/andersen-banner-bg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
}

section.carpentry-banner-area div, section.carpentry-banner-area img {
  position: relative;
  z-index: 2;
}

section.carpentry-banner-area h1, section.carpentry-banner-area h4, section.carpentry-banner-area p {
  color: #fff;
  z-index: 2;
}


section.carpentry-banner-area {
  position: relative;
  background: url(https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/andersen-banner-bg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
}

section.carpentry-banner-area div, section.carpentry-banner-area img {
  position: relative;
  z-index: 2;
}

section.carpentry-banner-area h1, section.carpentry-banner-area h2, section.carpentry-banner-area p {
  color: #fff;
  z-index: 2;
}

section.carpentry-banner-area h1 {
  font-size: 3rem;
}

section.carpentry-banner-area:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #cf3535, #cf3535b6 99%);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

section img.sunrise-carpentry-logo {
  width: 375px;
  padding: 4px;
  background: transparent;
  border: 4px solid #fff;
}

.main_btn_border {
  border: 2px solid #fff;
}

.p-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.tips-ideas {
  color: #000;
  font-size: 17px;
}

.mt-3em {
  margin-top: 3em;
}

a.z-index-priority {
  z-index: 10;
}

.moto_text h1, .moto_text h2 {
  color: white;
}

.andersen-banner-container {
  margin-top: 2em;
}

.mobile-hide img {
  margin-left: 6px;
}

.andersen_choose_contract_area {
  background: #fff;
}

.andersen-page-banner {
  position: relative;
  padding-top: 1em;
}

a.footer-phone-number {
  font-family: "Poppins", sans-serif;
  color: #ff9400;
  margin-left: 20px;
  line-height: 51px;
  z-index: 2;
  font-weight: bold;
  letter-spacing: 0.25px;
  text-shadow: 2px 2px 2px #000;
}

a.footer-phone-number img {
  margin-left: 6px;
  padding-right: 6px;
  margin-top: -2px;
  width: 23px;
}

div.grecaptcha-badge {
  visibility: hidden;
}

.about-page-highlights {
  color: #000;
}

img.certifications-group-image {
  width: 100%;
}

img.blog-thumbnail {
  box-shadow: 5px 5px 18px 0px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  transition: ease-in-out 250ms;
}

img.hp-thumbnail {
  box-shadow: 5px 5px 18px 0px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  transition: ease-in-out 250ms;
  transform: rotate(5deg);
}

img.hp-thumbnail:hover {
  transform: scale(1.025);
  transition: ease-in-out 250ms;
}

.capitalize {
  text-transform: capitalize;
}

.main_btn.main-btn-tips {
  border: 2px solid #cf3535;
}

.main_btn.main-btn-tips:hover {
  color: #cf3535;
  border: 2px solid #cf3535;
}

.script-text {
  font-family: 'Allura', cursive !important;
  font-weight: bold;
}

h1.article-title {
  font-size: 1.75em;
  color: #000;
}

h2.article-subtitle {
  font-size: 1.75em;
  color: #cf3535;
}

.article-title-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.article-title-flex div {
  flex: 1;
}

.article-title-flex .flex-left {
  margin-right: 1em;
  margin-bottom: 3em;
}

.article-title-flex .flex-right {
  display: flex;
  justify-content: center;
  width: 100%;
}

.blog_text h5 {
  color: #000;
}

.checklist-section {
  border-top: 2px solid lightgray;
  padding-top: 80px;
  margin-bottom: 1em;
  padding-bottom: 3em;
}

.checklist-section.choose_contract_area {
  background: #fff;
}

section.testimonials_area.pad_top.pb_0 {
  padding-bottom: 0;
}

.bt-lightgray {
  border-top: 2px solid lightgray;
}

.pt-40px {
  padding-top: 40px;
}

.step2-image {
  width: 100%;
  height: auto;
  margin-top: 2em;
  box-shadow: 0 0px 18px rgba(0, 0, 0, 0.27);
}

.choose_contract_area .contract_part .contract_text div.upgrades-container .row h4 {
  color: #000;
  font-size: 1.5em;
}

.red-square {
  width: 1em;
  height: 1em;
  background: white;
  border: 3px solid #cf3535;
  margin-top: 6px;
}

.w-100 {
  width: 100%;
}

.main_service_area * {
  cursor: pointer;
}

.service_img img {
  max-height: 245px;
}

.service_img {
  position: relative;
}

@media (max-width: 578px) {
  .service_img img {
    max-height: unset;
    width: 100%;
  }
  .velux-banner-logo {
    margin-top: 2em;
  }
}

.service_img img.img-sponsor-thumbnail {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 70px;
  width: 70px;
}

.service_img img.img-sponsor-thumbnail.sponsor-thermatru {
  width: 150px;
}

.service_img img.img-sponsor-thumbnail.sponsor-trex {
  width: 110px;
  right: 5px;
}

.service_img img.sponsor-timbertech {
  top: 50px;
  width: 110px;
}

.service_img img.img-sponsor-thumbnail.sponsor-velux {
  width: 90px;
}

.border-white-2px {
  border: #fff 2px solid;
}

.horizontal-rule {
  position: relative;
  border-top: 2px solid lightgray;
  height: 50px;
  width: 100%;
}


/* End Footer Area css
============================================================================================ */
/*---------------------------------------------------- */

.left_widge.contact_wd .text p, .left_widge.contact_wd .text h4 {
  text-align: center;
}

.text-align-center {
  text-align: center;
}

.white {
  color: white;
}

.form-control {
  height: 55px;
    padding: 0 20px;
    border-radius: 0;
    border: 1px solid #e1e1e1;
    outline: none;
    box-shadow: none;
}

.form-label {
  color: #333;
  font-weight: 600;
}

.estimate-form-wrapper {
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  padding-top: 3em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.estimate-form-wrapper form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.btn-primary {
  background: #cf3535;
  border-radius: 3px;
  display: inline-block;
  color: #fff;
  line-height: 40px;
  border: 1px solid #cf3535;
  padding: 0 22px;
  font-size: 14px;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  transition: all .3s linear 0s;
  outline: none!important;
  box-shadow: none!important;
}

.btn-primary:hover {
  background: #fff;
  color: #cf3535;
  border: 1px solid #cf3535;
}

.response-sent {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
}

.main_btn:hover {
  color: #cf3535;
  border: 1px solid #cf3535;
}
.breadcrumb_area.estimate-breadcrumbs {
  padding-bottom: 25px;
}

.btn.btn-primary {
  max-width: 300px;
}


@media (max-width: 765px)  {
  .replacement_area .media .media-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .replacement_area .media .media-body h4 {
    text-align: center;
  }
  .btn.btn-primary {
    max-width: unset;
    width: 100%;
  }
}

.showroom-image {
  max-width: 100%;
}

.pt-100px {
  padding-top: 100px;
}

.pb-100px {
  padding-bottom: 100px;
}

.fa.fa-angle-down.mobile_dropdown {
  min-width: 30px;
  height: 30px;
}

/*# sourceMappingURL=style.css.map */
